


























import { Component, Mixins, Vue } from 'vue-property-decorator'
import LabelBase from '@/components/atoms/LabelBase.vue'
import LearningType from '@/mixins/historyv3/LearningType'

@Component({
  components: {
    LabelBase,
  },
})
export default class TeacherHistoryHeader extends Mixins(LearningType) {
  get isActive(): any {
    return function (target: string) {
      // @ts-ignore
      const { path } = this.$route
      const active = path.split('/').slice(-1)[0]
      return {
        active: target === active,
      }
    }
  }
}
