































































import { Component, Vue, Mixins } from 'vue-property-decorator'
import StudentHistorySidebar from '@/components/organisms/StudentHistorySidebar.vue'
import TeacherHistoryHeader from '@/components/organisms/TeacherHistoryHeader.vue'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import SelectWide from '@/components/molecules/SelectWide.vue'
import SelectSubject from '@/components/atoms/SelectSubject.vue'
import TitleBase from '@/components/atoms/TitleBase.vue'
import TitleTextBase from '@/components/atoms/TitleTextBase.vue'
import ClassModeMethods from '@/mixins/utils/ClassModeMethods'
import LoadEnableSubjectsApi from '@/mixins/utils/LoadEnableSubjectsApi'
import TeacherHistoryBottomPanel from '@/components/organisms/TeacherHistoryBottomPanel.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'

type ResponnseLearnigData = {
  publisherName: string
  curriculumSUnitName: string
  movieDuration?: number
  practiceDuration?: number
  testDuration?: number
  testResult?: {
    correct?: number
    total?: number
  }
}

@Component({
  components: {
    StudentHistorySidebar,
    TeacherHistoryHeader,
    ColoredBox,
    SelectWide,
    SelectSubject,
    TitleBase,
    TitleTextBase,
    TeacherHistoryBottomPanel,
    ButtonBase,
  },
})
export default class LessonSituation extends Mixins(ClassModeMethods, LoadEnableSubjectsApi) {
  private student: {
    userId: number
  } = Vue.prototype.$cookies.get('dataGdls').student || {}

  private learningDatas: any[] = []
  private selectedSubject = ''
  private selectedData = ''
  private loading = true

  private branchId = Vue.prototype.$cookies.get('dataGdls').branchId

  private academyCode = ''

  private get checkSelectZenyoken() {
    return [this.ASSIST_MATERIAL_CODE.HD, this.ASSIST_MATERIAL_CODE.FC, this.ASSIST_MATERIAL_CODE.KT].includes(
      this.selectedData
    )
  }

  private get dataZenyoken() {
    return this.getPrivateDate(this.selectedData, this.MAP_SERVICE_DATA[this.selectedData])
  }
  // 教科プルダウン表示対象、教科コードの配列 ex.=> ['su', 'ei', 'ko']
  private enableSubjects: string[] = []

  // 教科プルダウン表示対象
  private get enableSubjectsByService(): string[] {
    if (this.checkSelectZenyoken) {
      // 全教研サービスの場合
      return this.enableSubjects.filter((subject) => this.dataZenyoken.subjectCodes.includes(subject))
    } else {
      // 全教研以外の場合
      return this.enableSubjects.filter((subject) =>
        this.assistMaterialSubjectsCode(this.selectedData).includes(subject)
      )
    }
  }

  private get materialTypeData() {
    let array = Object.keys(this.assistMaterialTexts).map((code) => {
      return { text: this.assistMaterialTexts[code], value: code }
    })

    // 全教研であれば全教研専用サービス（ここでは塾コード）をプルダウンに追加
    // TODO 他の塾専用サービスも開始される場合は拡張が必要
    if (this.academyCode === this.PRIVATE_SERVICE_ACADEMY_CODE) {
      array = array.concat(
        {
          text: this.privateServiceTexts.HD,
          value: this.ASSIST_MATERIAL_CODE.HD,
        },
        {
          text: this.privateServiceTexts.FC,
          value: this.ASSIST_MATERIAL_CODE.FC,
        },
        {
          text: this.privateServiceTexts.KT,
          value: this.ASSIST_MATERIAL_CODE.KT,
        }
      )
    }

    return array
  }

  private async mounted(): Promise<void> {
    await this.loadEnableSubjects()
    await this.loadAcademyCode()

    // デフォルトのクラスモードと教科をセット
    this.selectedData = this.ASSIST_MATERIAL_CODE.OO
    this.selectedSubject = this.enableSubjectsByService.length > 0 ? this.enableSubjectsByService[0] : ''

    this.loadLearningDatas()
  }

  private async loadLearningDatas(): Promise<void> {
    const params = {
      userId: this.student.userId,
      subjectCode: this.selectedSubject || this.enableSubjectsByService[0],
    }
    // 全教研専用サービスの場合
    if (this.checkSelectZenyoken) {
      params['assistMaterialCode'] = this.selectedData
      params['serviceCode'] = this.MAP_SERVICE_DATA[this.selectedData]
    } else {
      params['assistMaterialCode'] = this.selectedData
      params['serviceCode'] = ''
    }

    const {
      data,
    }: {
      data: ResponnseLearnigData[]
    } = await Vue.prototype.$http.httpWithToken.get('/history/resultDrills/assistMaterial', { params })

    this.learningDatas = data.map((info) => {
      const name = info.publisherName ? `${info.publisherName} - ${info.curriculumSUnitName}` : info.curriculumSUnitName
      return {
        name,
        result: {
          video: info.movieDuration,
          practice: info.practiceDuration,
          test: { times: info.testDuration, point: info.testResult?.correct, total: info.testResult?.total },
        },
      }
    })
    this.loading = false
  }

  private convertDurationText(duration: number): string {
    if (duration === 0) return '未実施'
    if (!duration) return '―'
    return String(Math.ceil(duration / 60))
  }

  private async onChangeSubject(): Promise<void> {
    await this.loadLearningDatas()
  }

  private async onChangeAssistMaterial(): Promise<void> {
    // クラスモードを切り替えた場合に、現在選択されている教科がそのクラスモードに存在しない場合は教科を変更
    if (this.checkSelectZenyoken && !this.dataZenyoken.subjectCodes.includes(this.selectedSubject)) {
      // 全教研専用サービスの場合
      this.selectedSubject = this.enableSubjectsByService.length > 0 ? this.enableSubjectsByService[0] : ''
    } else if (!this.assistMaterialSubjectsCode(this.selectedData).includes(this.selectedSubject)) {
      // 全教研専用サービス以外の場合
      this.selectedSubject = this.enableSubjectsByService.length > 0 ? this.enableSubjectsByService[0] : ''
    }
    await this.loadLearningDatas()
  }

  /**
   * サービス設定で有効な教科を取得し、教科プルダウンに反映
   */
  private async loadEnableSubjects() {
    // プルダウン候補の取得
    this.enableSubjects = (await this.loadGdlsSubjectsApi(this.branchId)).map((subject) => {
      return subject.code
    })
  }

  /**
   * 塾コードを取得する
   */
  private async loadAcademyCode() {
    if (this.branchId) {
      await Vue.prototype.$http.httpWithToken.get(`/branches/${this.branchId}/academyCode`).then((res: any) => {
        this.academyCode = res.data.code
      })
    }
  }
}
